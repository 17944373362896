div.header {
	display: inline-block;
    width: calc(100% - 148px);
}
div.title {
	display: inline-block;

    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    font-size: 16px;
    line-height: 1.4;
}
div.extraHeader {
    /* line-height: 1.4; */
    float: right;
    /* vertical-align: middle; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 62px;
}
@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;