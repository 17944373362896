.print {
    display: none;
}

@media print {
    .print {
        display: block;
    }

    .price {
        page-break-after: always;
        page-break-inside: avoid;
        border: none !important;

    }

    .price.discount, .price.discountSmall {
        border: 1px solid #666 !important;

    }
}

.price {
    width: calc(7cm - 1px);
    height: 16.9mm;
    border: 1px solid #666;
    position: relative;
    display: flex;
                font-family: 'Montserrat',  Helvetica, sans-serif;
    box-sizing: border-box;
    color: black;
    font-variant: normal;
    line-height: normal;
    float: left;




    :global {

        .left {
            font-size: 2.6mm;
            height: 16.9mm;
            padding: 1mm;
            box-sizing: border-box;
            position: relative;
            margin: -1px 0 0 -1px;
            padding-left: 6mm;

        }
        .right {
            height: 16.9mm;
            padding: 1mm;
            padding-right: 6mm;
            box-sizing: border-box;
            text-align: right;
            flex: 1;
        }

        .name {
            line-height: 2.3mm;
            letter-spacing: -0.1mm;
            overflow-wrap: break-word;
            hyphens: auto;

            strong {
                font-family: 'Montserrat',  Helvetica, sans-serif;
                font-weight: 700;
                padding-bottom: 0.5mm;
                display: block;
            }
        }


        .amount {
            letter-spacing: -0.5mm;

            font-weight: 700;
            font-size: 10mm;
            margin-top: -2.5mm;

        }
        .perUnit {
            font-weight: 300;
            font-size: 3mm;
            margin-top: -2.5mm;

        }

        .amount sup {
            font-size: 5mm;
            font-weight: 700;
            position: inherit;
            vertical-align: super;

        }

        .amount span {
            font-weight: 400;
            font-size: 10mm;
                font-family: 'Montserrat',  Helvetica, sans-serif;
        }

        .amountBulk {
            letter-spacing: -0.5mm;
            font-weight: 700;
            font-size: 8mm;
            margin-top: -2mm;

        }
        .amountBulk div {
            display: inline-block;
            margin: 1mm 2mm;
            font-size: 8mm;

        }

        .amountBulk sup {
            font-size: 4mm;
            font-weight: 700;
            position: inherit;
            vertical-align: super;

        }

        .amountBulk span {
            font-weight: 400;
            font-size: 8mm;
                font-family: 'Montserrat',  Helvetica, sans-serif;
        }
.oldAmount span {
            font-weight: 400;
             font-size: 6mm;
                font-family: 'Montserrat',  Helvetica, sans-serif;
        }

        .oldAmount {
            font-size: 6mm;
            font-weight: 700;
            height: 5mm;
            line-height: 5mm;
            position: relative;
            color: #333;
            visibility: hidden;
            display: inline-block;
        }

        .oldAmount sup {
            font-size: 1.6mm;
            font-weight: 700;
            position: inherit;
            vertical-align: super;

        }

        .oldAmount::before {
            transform: skewY(-15deg);
            opacity: 0.7;
        }

        .oldAmount::after {
            transform: skewY(15deg);
        }

        .oldAmount::before, .oldAmount::after {
            border-bottom: 2px solid black;
            opacity: 0.7;

            content: '';
            left: 0mm;
            position: absolute;
            right: 2mm;
            top: 50%;
        }

        .ean {
            font-weight: 500;
            font-size: 1.3mm;
            position: absolute;
            bottom: 4.8mm;
            left: 6mm;

        }

        .ean .label {
            font-weight: 300;
            font-size:1.3mm;
        }

        .sku {
            font-weight: 700;
            font-size: 5.5mm;
            position: absolute;
            bottom: -0.5mm;
            left: 6mm;
            letter-spacing: -0.3mm;


        }

        .sku .label {
            font-weight: 300;
            letter-spacing: -0.5mm;
            font-size: 5mm;

        }

        .mask {
            position: absolute;
            left: 1px;
            right: 0;
            top:7.5mm;
            bottom: 2px;
            background: rgb(255,255,255);
            background: linear-gradient(180deg, rgba(255,255,255,0) 0mm, rgba(255,255,255,1) 3mm, rgba(255,255,255,1) 100%);

            &.noEan {
                top:11mm;
                background: linear-gradient(180deg, rgba(255,255,255,0) 0mm, rgba(255,255,255,1) 3mm, rgba(255,255,255,1) 100%);
            }
        }

    }

}

    .small {
        width: calc(4.85cm - 1px);
        :global {

            .left {
                padding-left:1mm;

            }
            .right {
                padding-right: 1mm;
            }
            .sku {
                left: 1mm;
            }
            .ean {
                left: 1mm;
            }
        }
    }

    .discount {
        height: auto;
        width: 15cm;
        border: 1px !important;
        
        :global div {
            width: 15cm;
            
            text-align: center;
            overflow: visible;
            padding-bottom: 2mm;

            h1 {
                letter-spacing: -1mm;
                margin: 0;
                font-weight: bolder;
                font-size: 2cm;
                font-family: 'Montserrat',  Helvetica, sans-serif;
            }
            h2 {
                letter-spacing: -0.5mm;
                margin: 0;
                font-weight: bold;
                font-size: 1cm;
                margin-bottom: 0.5cm;
            }

            div.discountReason {
                font-size: 5mm;
            }
            div.discountEnd {
                font-size: 5mm;
            }
            .discountText {
                font-size: 15mm;
                font-weight: bold;
                    line-height: 12mm;
            }

            .left, .right {
                width: 50%;
                float: left;
                text-align: center;
                height: auto;
                padding: 0;
                margin-bottom: 0.5cm;
            }

            .amount {
                letter-spacing: -.5mm;

                font-weight: 700;
                font-size: 20mm;
                margin-top: -2.5mm;

            }
            .amount sup {
                font-size: 10mm;
                font-weight: 700;
                position: inherit;
                vertical-align: super;

            }

            .amount span {
                font-size: 20mm;
            }

            .oldAmount {
                letter-spacing: -.5mm;

                font-weight: 700;
                font-size: 20mm;
                margin-top: -2.5mm;
                height: 20mm;
                line-height:20mm;
                position: relative;
                color: #333;
                visibility: visible;
                display: inline-block;
                margin-top: 0.2cm;
            }

            .oldAmount span {
                font-size: 20mm;
            }

            .oldAmount sup {
                font-size: 10mm;
                font-weight: 700;
                position: inherit;
                vertical-align: middle;

            }

            .oldAmount::before {
                transform: skewY(-15deg);
                opacity: 0.7;
            }

            .oldAmount::after {
                transform: skewY(15deg);
            }

            .oldAmount::before, .oldAmount::after {
                border-bottom: 6px solid black;
                opacity: 0.7;

                content: '';
                left: 0mm;
                position: absolute;
                right: 2mm;
                top: 50%;
            }
        }
    }
    .discountSmall {
        height: auto;
        width: 7.5cm;
        border: 1px !important;

        :global div {
            width: 7.5cm;
            
            text-align: center;
            overflow: visible;
            padding-bottom: 1mm;

            h1 {
                letter-spacing: -0.5mm;
                margin: 0;
                font-weight: bolder;
                font-size: 1cm;
                font-family: 'Montserrat',  Helvetica, sans-serif;
            }
            h2 {
                letter-spacing: -0.25mm;
                margin: 0;
                font-weight: bold;
                font-size: 0.5cm;
                margin-bottom: 0.25cm;
            }

            div.discountReason {
                font-size: 2.5mm;
            }
            div.discountEnd {
                font-size: 2.5mm;
            }
            .discountText {
                font-size: 7.5mm;
                font-weight: bold;
                    line-height: 6mm;
            }

            .left, .right {
                width: 50%;
                float: left;
                text-align: center;
                height: auto;
                padding: 0;
                margin-bottom: 0.25cm;
            }

            .amount {
                letter-spacing: -.25mm;

                font-weight: 700;
                font-size: 1cm;
                margin-top: -1.25mm;

            }
            .amount sup {
                font-size: 5mm;
                font-weight: 700;
                position: inherit;
                vertical-align: super;

            }

            .amount span {
                font-size: 10mm;
            }

            .oldAmount {
letter-spacing: -.25mm;

                font-weight: 700;
                font-size: 10mm;
                margin-top: -1.25mm;
                height: 10mm;
                line-height:10mm;
                position: relative;
                color: #333;
                visibility: visible;
                display: inline-block;
                margin-top: 0.1cm;
            }

            .oldAmount span {
                font-size: 10mm;
            }

            .oldAmount sup {
                font-size: 5mm;
                font-weight: 700;
                position: inherit;
                vertical-align: middle;

            }

            .oldAmount::before {
                transform: skewY(-15deg);
                opacity: 0.7;
            }

            .oldAmount::after {
                transform: skewY(15deg);
            }

            .oldAmount::before, .oldAmount::after {
                border-bottom: 3px solid black;
                opacity: 0.7;

                content: '';
                left: 0mm;
                position: absolute;
                right: 1mm;
                top: 50%;
            }
        }
    }

    .big {
        width: calc(10.5cm - 1px);
        height: 7cm;
        display: block;
        padding-left: 6mm;
        padding-right: 6mm;
        :global {
            .left {
                font-size: 10mm;
                font-weight: 500;
                height: auto;
                padding: 0.5mm;
                box-sizing: border-box;
                position: relative;
                text-align: center;
            }

            .right {
                text-align: center;
            }
            .name {
                padding: 0, 2mm;
                font-size: 5mm;
                font-weight: 600;
                div, strong {
                    font-size: 5mm;
                }
                border-bottom: 1px solid black;
                line-height: initial;
                letter-spacing: -0.4mm;
                overflow-wrap: break-word;
                hyphens: auto;
                height: 20mm;
                margin-bottom: 1mm;
                overflow: none;
                strong {
                font-family: 'Montserrat',  Helvetica, sans-serif;
                    font-weight: 700;
                    padding-bottom: 0mm;
                    display: block;
                }
            }

            .mask {
                display: none;
            }
 .amount {
            letter-spacing: -.5mm;

            font-weight: 700;
            font-size: 18mm;
            margin-top: -2.5mm;

        }
        .amount sup {
            font-size: 9mm;
            font-weight: 700;
            position: inherit;
            vertical-align: super;

        }

        .amount span {
            font-size: 18mm;
        }

        .oldAmount {
            letter-spacing: -.5mm;
            font-size: 12mm;
            font-weight: 700;
            height: 12mm;
            line-height:12mm;
            position: relative;
            color: #333;
            visibility: visible;
            display: none;
        }

        .oldAmount span {
            font-size: 12mm;
        }

        .oldAmount sup {
            font-size: 4mm;
            font-weight: 700;
            position: inherit;
            vertical-align: super;

        }

        .oldAmount::before {
            transform: skewY(-15deg);
            opacity: 0.7;
        }

        .oldAmount::after {
            transform: skewY(15deg);
        }

        .oldAmount::before, .oldAmount::after {
            border-bottom: 4px solid black;
            opacity: 0.7;

            content: '';
            left: 0mm;
            position: absolute;
            right: 2mm;
            top: 50%;
        }
         .ean {
            font-weight: 500;
            font-size:7mm;
            position: absolute;
            bottom: 7mm;
            left: 0.5mm;

        }

        .ean .label {
            font-weight: 300;
            font-size:7mm;
        }

        .sku {
            font-weight: 700;
            font-size: 7mm;
            position: absolute;
            bottom: -0.5mm;
            left: 6mm;
            letter-spacing: -0.3mm;


        }

        .sku .label {
            font-weight: 300;
            letter-spacing: -0.5mm;
            font-size: 7mm;

        }

        }
    }

.stickerChooserPaper {
    width: calc(210mm / 3);
    height: calc(297mm / 3);
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    overflow: hidden;
    display: flex;
    align-items: center;

    :global {
        div {
            width: calc(210mm / 3);
        }
        button {
            padding: 0;
            display: block;
            float: left;
            border-radius: 0;
            margin: 0 -2px -2px 0;
            box-sizing: content-box;
            position: relative;

            &.ant-btn-default {
                border-color: #333;
            }
            &.ant-btn-dashed {
                span {
                    display: none !important;
                }

            }

            span {
                position: absolute !important;
                display: none;
                background: #999;

            }

            .scName {
                width: 1cm;
                height: 1mm;
                top: 1mm;
                left:1mm;
            }
            .scSku {
                width: 3mm;
                height: 1.5mm;
                bottom: 1mm;
                left: 1mm;
            }
            .scPrice {
                width: 7mm;
                height: 3mm;
                right: 1mm;
                top: 1mm;
            }
        }
    }
}

.stickerChooserStickerRegular {
    width: 33%;
    height: calc(16.9mm / 3);
    :global {
        span {
            display: block !important;
        }
    }
}

.stickerChooserStickerSmall {
    width: 25%;
    height: calc(16.9mm / 3);
    :global {
        span {
            display: block !important;
        }
        .scName {
            width: 6mm !important;
        }
    }
}

.stickerChooserStickerBig{
    width: 50%;
    height: calc(7cm / 3);
    :global {
        span {
            display: block !important;
        }
        .scName {
            width: 2cm !important;
            height: 4mm !important;
            left: 50% !important;
            margin-left: calc(-1cm - 1mm);
        }
        .scPrice {
            width: 1cm !important;
            height: 5mm !important;
            left: 50% !important;
            margin-left: calc(-5mm - 1mm);
            top: 8mm !important;
        }
        .scPrice1 {
            width: 1cm !important;
            height: 2mm !important;
            left: 50% !important;
            margin-left: calc(-12mm - 1mm);
            top: 16mm !important;
        }
        .scPrice2{
            width: 1cm !important;
            height: 2mm !important;
            left: 50% !important;
            margin-left: calc( - 1mm);
            top: 16mm !important;
        }
    }
}

@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;