.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    // margin: -24px -24px;
}

.calendar {
    :global {
        .ant-picker-calendar-header {
            display: none;
        }
        .ant-picker-calendar-date{
            height: calc((100vh - 170px) / 6) !important;
            .ant-picker-calendar-date-content {
                height: auto;
            }
        }
        .ant-fullcalendar-fullscreen .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
            border-top-width: 1px;
        }
    }
}

.divider {
    margin: 4px 0;
}
@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;