.print {
    width:20cm;
    display: none;
}

.addButton {
    margin-top: 30px;
}

@media print {
    .print {
        width:20cm;
        display: block;
        

    }

    .declaration {
        page-break-after: always;
        page-break-inside: avoid;
    }
}

.declaration {
    width: 10cm;
    // height: 18mm;
    border: 1px solid #666;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    color: black;
    font-variant: normal;
    line-height: normal;
    float: left;
    letter-spacing: -0.1mm;

    :global {
        strong {
            font-family: 'Montserrat', sans-serif;
        }
        
        .container {
            display: flex;

            .left {
                font-size: 3mm;
                padding: 0.5mm;
                box-sizing: border-box;
                position: relative;
                margin: -1px 0 0 -1px;

            }
            .right {
                padding: 0.5mm;
                box-sizing: border-box;
                text-align: right;
                flex: 1;
                min-width: 4cm;
            }

        }

        p {
            font-size: 3mm;
            line-height: 2.5mm;
            font-weight: 200;
            margin: 0;
            padding: 0.5mm;
            &.center {
                text-align: center;
            }
        }
        
        .name, .name div {
            line-height: 3.5mm;
            font-size: 4mm;
            overflow-wrap: break-word;
            hyphens: auto;
            font-weight: 700;
            margin-bottom: 5mm;
            font-family: 'Montserrat', sans-serif;
        }


        .amount {
            letter-spacing: -0.5mm;

            font-weight: 700;
            font-size: 10mm;
            margin-top: -2.5mm;

        }
        .perUnit {
            font-weight: 300;
            font-size: 3mm;
            margin-top: -2.5mm;

        }

        .amount sup {
            font-size: 5mm;
            font-weight: 700;
            position: inherit;
            vertical-align: super;

        }

        .expiration {
            font-size: 3mm;
            position: relative;
            color: #333;
            display: inline-block;

            strong {
                font-size: 3mm;
                font-family: 'Montserrat', sans-serif;
            }
        }

        .ean {
            font-weight: 500;
            font-size: 1.8mm;
            position: absolute;
            bottom: 4.8mm;
            left: 0.5mm;

        }

        .ean .label {
            font-weight: 300;
            font-size:1.8mm;
        }

        .sku {
            font-weight: 700;
            font-size: 5.5mm;
            letter-spacing: -0.3mm;

            svg {
                width: 5cm !important;
                margin-bottom: -1mm;
            }
        }

        .sku .label {
            font-weight: 300;
            letter-spacing: -0.5mm;
            font-size: 5mm;

        }

        .mask {
            position: absolute;
            left: 1px;
            right: 0;
            background: red;
            top:8mm;
            bottom: 2px;
            background: rgb(255,255,255);
            background: linear-gradient(180deg, rgba(255,255,255,0) 0mm, rgba(255,255,255,1) 3mm, rgba(255,255,255,1) 100%);

            &.noEan {
                top:11mm;
                background: linear-gradient(180deg, rgba(255,255,255,0) 0mm, rgba(255,255,255,1) 3mm, rgba(255,255,255,1) 100%);
            }
        }

    }

}


@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;