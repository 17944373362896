.erp-userMenu {
    float: right;
    margin: 0 24px;

    .clickArea {
        height: 64px;
        line-height: 64px;
        display: inline-block;
    }
    
    .ant-avatar {
        margin: 0 8px 0 0;
    }


}
.erp-userMenu-dropdown {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;