.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: -24px -24px;
}

.saleContainer {
    padding: 24px 0 0 0;
}

.tabs {
    margin: 0;
    border-bottom: none;

        :global .ant-tabs-tabpane {
            padding:7px 24px 0 24px !important;
        }

    :global .ant-tabs-nav-wrap {
        padding: 0 24px;
        margin: 0;
        background: @background-color-light;
        box-shadow: inset 0 -1px 3px #eee;

        .ant-tabs-tab {
            padding: 16px 16px;
            z-index: 1;
        }


        .ant-tabs-tab-active {
            background: lighten(@primary-color,30);
        }

        .ant-tabs-ink-bar {
            background: @primary-color;
            z-index: 100;
        }
    }

}

.subTotal {
    margin-top: -14px;
    :global {
        .antd-pro-number-info-numberInfoTitle {
            font-size: 12px;
            margin-bottom: 0;
        }
        .antd-pro-number-info-numberInfoValue {
            margin-top: 0;
            span {
              height: auto;
            }
        }

        h2 {
            font-size: 40px;
        }
    }
}

.keys {
    padding: 5px 24px;
    // background: @background-color-light;
    box-shadow: inset 0 0px 3px #eee;
    margin-bottom: -23px;
}

.addButton {
    display: block;
}

.unblockButton {
    display: block;
    margin-right: 8px;
}


// .active, .inactive {
//     width: 16px;
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
// }

.active {
    color: @primary-color;
}

.strikethrough {
    text-decoration: line-through;
}
@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;