.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
}

.noLabel {
    padding-bottom: 0px !important;
    :global .ant-descriptions-item-colon {
        display: none;
        padding-bottom: 16px;
    }
}

.descriptions {
    margin-bottom: 16px;
}
@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;