div.key
{
display: inline-block;
	color: #666;
	font-size: 12px;
	text-decoration: none;
	text-align: center;
	padding: 0 5px;
	margin: 5px;
	background: #fefefe;
	border-radius: 2px;
	
	box-shadow: 
		0 0px 1px #999, 0 2px 0px #eee, 0 3px 1px #ccc;
	text-shadow: 1px 1px 1px #f9f9f9;
}

div.text {
	display: inline-block;
	vertical-align: middle;
	padding: 2px 0;
}

div.wrapper {
	display: inline-block;
}
@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;