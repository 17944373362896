@import '~antd/es/style/themes/default.less';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background-color: @layout-body-background;
}

.lang {
  width: 100%;
  height: 40px;
  line-height: 44px;
  text-align: right;
  :global(.ant-dropdown-trigger) {
    margin-right: 24px;
  }
}

.content {
  background: #c0a1c8;
  background: -moz-radial-gradient(center, ellipse cover, #c0a1c8 0%, #b18bbd 100%);
  background: -webkit-radial-gradient(center, ellipse cover, #c0a1c8 0%,#b18bbd 100%);
  background: radial-gradient(ellipse at center, #c0a1c8 0%,#b18bbd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0a1c8', endColorstr='#b18bbd',GradientType=1 );
  flex: 1;
  padding: 32px 0;
}

.top {
  text-align: center;
}

.header {
  height: 44px;
  line-height: 44px;
  a {
    text-decoration: none;
  }
}

.logo {
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}

.title {
  position: relative;
  top: 2px;
  color: #fff;
  font-weight: 600;
  font-size: 33px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  text-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}

.desc {
  margin-top: 12px;
  margin-bottom: 40px;
  color: @text-color-secondary;
  font-size: @font-size-base;
}

.form {
  width: 380px;
  margin: 40px auto;
  padding: 0 24px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);

}
@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;