.nestedTable {
    :global .ant-table{
        // border-width: 0 0 1px 0;
        // margin: 0 -1px;
        // border-radius: 0;
    }
}

.drawer {
    :global .ant-drawer-body {
        padding: 0;
        margin-top: -1px;
       
        :global .ant-tabs-nav {
            margin-bottom: 0px;

            .ant-tabs-tab {
                padding: 12px 16px;
            }
        }

       
        :global .ant-tabs-tabpane {
            tr {
                td:first-child, th:first-child {
                    padding-left: 16px;
                }
            }
        //     overflow: auto;
        //     :global .ant-table-wrapper {
        //         margin-top: -1px;
        //     }
        }
        :global .ant-table-expanded-row > .ant-table-cell {
            padding-left: 0;
            padding-top: 0;
            .ant-table-container {
                margin-top: 11px;
            }
        }
        // :global .ant-table-expanded-row > td {
        //     padding-top: 11px !important;
        // }
    }
}
@primary-color: #B789BC;@background-color-light: hsv(0, 0, 99%);@menu-icon-size-lg: 32px;